.reset_balance_dialog /deep/ .el-dialog__body {
  .top {
    margin-bottom: 40px;
    font-size: 18px;

    .name {
      font-weight: bold;
      line-height: 1.44;
      color: $primary-purple;
      word-break: break-word;
      margin-bottom: 8px;
    }

    .number {
      font-weight: 300;
      line-height: 1.44;
      color: $primary-purple;
    }
  }
}

@media (max-width: 550px) {
  .reset_balance_dialog /deep/ .el-dialog__body {
    .top {
      margin-bottom: 30px;

      .name,
      .number {
        font-size: 16px;
      }
    }
  }
}
